import styles from './Block.module.scss';

import { Fragment, useState } from 'react';
import Button from '@/components/atoms/Button';
import cn from 'classnames';
import Column from '@/components/atoms/Column';
import Container from '@/components/atoms/Container';
import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';
import Lever from '@/components/molecules/Lever';
import Row from '@/components/atoms/Row';
import typeBlock from '@/utilities/types/Block';
import typeSettings from '@/utilities/types/Settings';
import Shortcodes from '@/utilities/helpers/Shortcodes';

const RoiCalculator = dynamic(
  () => import('@/components/molecules/RoiCalculator'),
);
const KustomerForm = dynamic(
  () => import('@/components/molecules/KustomerForm'),
);
const Box = dynamic(() => import('@/components/molecules/Box'));
const Buttons = dynamic(() => import('@/components/molecules/Buttons'));
const Card = dynamic(() => import('@/components/molecules/Card'));
const Chip = dynamic(() => import('@/components/atoms/Chip'));
const Columns = dynamic(() => import('@/components/molecules/Columns'));
const Faq = dynamic(() => import('@/components/molecules/Faq'));
const Feature = dynamic(() => import('@/components/molecules/Feature'));
const Form = dynamic(() => import('@/components/molecules/Form'));
const Gallery = dynamic(() => import('@/components/molecules/Gallery'));
const Highlight = dynamic(() => import('@/components/molecules/Highlight'));
const Image = dynamic(() => import('next/image'));
const Logos = dynamic(() => import('@/components/molecules/Logos'));
const Product = dynamic(() => import('@/components/molecules/Product'));
const Quote = dynamic(() => import('@/components/molecules/Quote'));
const Spacer = dynamic(() => import('@/components/atoms/Spacer'));
const Spotlight = dynamic(() => import('@/components/molecules/Spotlight'));
const Stats = dynamic(() => import('@/components/molecules/Stats'));
const Table = dynamic(() => import('@/components/atoms/Table'));
const Testimonials = dynamic(
  () => import('@/components/molecules/Testimonials'),
);
const Typography = dynamic(() => import('@/components/atoms/Typography'));
const Video = dynamic(() => import('@/components/molecules/Video'));
const Wpforms = dynamic(() => import('@/components/atoms/Wpforms'));
const Checks = dynamic(() => import('@/components/molecules/Checks'));
const SignUpForm = dynamic(() => import('@/components/molecules/SignUpForm'));
const Accordion = dynamic(() => import('@/components/molecules/Accordion'));
const TestimonialCta = dynamic(
  () => import('@/components/molecules/TestimonialCta'),
);
const IconsLIst = dynamic(() => import('@/components/molecules/IconsList'));
const RecentPosts = dynamic(() => import('@/components/molecules/RecentPosts'));
const HubSpotForm = dynamic(() => import('@/components/molecules/HubSpotForm'));
const LinksColumns = dynamic(
  () => import('@/components/molecules/LinksColumns'),
);
const Eventbrite = dynamic(() => import('@/components/molecules/Eventbrite'));
const Embeds = dynamic(() => import('@/components/molecules/Embeds'));
const TestimonialsNew = dynamic(
  () => import('@/components/molecules/TestimonialsNew'),
);

interface BlockProps {
  block: typeBlock;
  index: number;
  priority: boolean;
  settings: typeSettings;
  tag: 'h1' | 'h2';
}

const Block = (props: BlockProps) => {
  const { block, index, priority, settings, tag } = props;

  const display = block.customise.display;
  const alignment = block.customise.alignment;
  const classnames = block.customise.classnames;
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  return (
    <section
      className={cn(
        styles.root,
        styles[block.customise.alignment],
        styles[block.customise.background],
        {
          [styles[block.customise.layout]]: block.customise.layout,
          [styles.overflow]: block.customise.overflow,
          [styles.hideMobile]: !display.mobile,
          [styles.hideDesktop]: !display.desktop,
        },
        classnames,
      )}
      id={
        block.id
          ? block.id.replaceAll(' ', '-').toLowerCase()
          : `row-${index + 1}`
      }
    >
      {block.customise.cover && (
        <Image
          alt={block.customise.cover.altText}
          className={styles.cover}
          height={block.customise.cover.mediaDetails.height || 520}
          priority={priority}
          src={block.customise.cover.mediaItemUrl}
          width={block.customise.cover.mediaDetails.width || 2246}
        />
      )}
      {block.content && (
        <Container>
          <Row
            justify={alignment}
            xs={
              block.customise.layout && block.customise.layout !== 'full-width'
                ? 2
                : 0
            }
          >
            <Column
              xs={12}
              lg={
                block.customise.layout &&
                block.customise.layout === 'full-width'
                  ? 12
                  : alignment === 'center'
                    ? 9
                    : 12
              }
            >
              {block.content.map((content, index) => (
                <Fragment key={index}>
                  {content?.testimonialsNew && (
                    <TestimonialsNew {...content.testimonialsNew} />
                  )}
                  {content.box && (
                    <Box
                      box={content.box}
                      className={cn(styles.box, styles.expand, styles.section)}
                      tag={block.content.some((e) => e.title) ? 'h3' : 'h2'}
                    />
                  )}
                  {content.cards && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.cards.map((card, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              lg={columns !== 12 ? 6 : undefined}
                              xl={
                                columns < 6
                                  ? content.customise && columns
                                  : undefined
                              }
                            >
                              <Card
                                card={card.card}
                                customise={card.customise}
                                heading="h3"
                                stretch={true}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.chip && (
                    <Chip className={styles.chip}>{content.chip}</Chip>
                  )}
                  {content.columns && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Columns
                        alignment={alignment}
                        columns={content.columns}
                      />
                    </div>
                  )}
                  {content.disclaimer && (
                    <Typography className={styles.disclaimer} xs={1}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.disclaimer),
                        }}
                      />
                    </Typography>
                  )}
                  {content.editor && (
                    <Typography className={styles.editor} xs={3} lg={4}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            Shortcodes(content.editor),
                          ),
                        }}
                      />
                    </Typography>
                  )}
                  {content.faq && (
                    <div className={styles.section}>
                      <Faq faqs={content.faq} style="page" />
                    </div>
                  )}
                  {content.features && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.features.map((feature, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              md={columns !== 12 ? 6 : undefined}
                              lg={columns < 6 ? columns : undefined}
                            >
                              <Feature
                                customise={feature.customise}
                                feature={feature.feature}
                                stretch={true}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.gallery && (
                    <div className={styles.section}>
                      <Gallery gallery={content.gallery} />
                    </div>
                  )}
                  {content.highlights && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.highlights.map((highlight, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              md={columns !== 12 ? 6 : undefined}
                              lg={columns < 6 ? columns : undefined}
                            >
                              <Highlight
                                className={cn(
                                  styles.highlight,
                                  styles[
                                    content.customise &&
                                    content.customise.alignment === 'center'
                                      ? 'center'
                                      : 'left'
                                  ],
                                )}
                                highlight={highlight.highlight}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.image && content.image.image && (
                    <div className={styles.section}>
                      <Image
                        alt={content.image.image.altText}
                        className={styles.image}
                        height={content.image.image.mediaDetails.height || 225}
                        src={content.image.image.mediaItemUrl}
                        style={{
                          maxHeight: content.image.height,
                          width: content.image.height && 'auto',
                        }}
                        width={content.image.image.mediaDetails.width || 400}
                      />
                    </div>
                  )}
                  {content.links && (
                    <Buttons buttons={content.links} className={styles.links} />
                  )}
                  {content.linksColumns && (
                    <div className={styles.expand}>
                      <LinksColumns {...content.linksColumns} />
                    </div>
                  )}
                  {content.logos && content.customise && (
                    <div className={styles.expand}>
                      <Logos
                        customise={content.customise}
                        gap="medium"
                        logos={content.logos}
                      />
                    </div>
                  )}
                  {content.hubspotForm && (
                    <div className={styles.section}>
                      <HubSpotForm form={content.hubspotForm} />
                    </div>
                  )}
                  {content.product && (
                    <Product
                      alignment={alignment}
                      className={styles.product}
                      product={content.product}
                    />
                  )}
                  {content.quotes && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.quotes.map((quote, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              md={columns !== 12 ? 6 : undefined}
                              lg={columns < 6 ? columns : undefined}
                            >
                              <Quote
                                direction={
                                  columns === 12 ? 'horizontal' : 'vertical'
                                }
                                quote={quote.quote}
                                size="medium"
                                stretch={true}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.spacer && <Spacer spacer={content.spacer} />}
                  {content.split && (
                    <div
                      className={cn(
                        styles.expand,
                        styles.section,
                        styles.split,
                        content.split.customise.disableReverseOnMobile &&
                          styles.disableReverseOnMobile,
                      )}
                    >
                      <Row
                        align={content.split.customise.vertical}
                        reverse={content.split.customise.reverse ? true : false}
                        xs={2}
                        lg={3}
                      >
                        <Column xs={12} lg={content.split.customise.width}>
                          {content.split.media.map((media, index) => (
                            <Fragment key={index}>
                              {media.card && (
                                <Card
                                  card={media.card}
                                  customise={{
                                    alignment: 'center',
                                  }}
                                  heading="h3"
                                  stretch={true}
                                />
                              )}
                              {media.embed && <Embeds content={media.embed} />}
                              {media.form && media.customise && (
                                <Form
                                  customise={media.customise}
                                  form={media.form}
                                  settings={settings}
                                />
                              )}
                              {media.image && (
                                <Image
                                  alt={media.image.altText}
                                  className={styles.image}
                                  height={
                                    media.image.mediaDetails.height || 608
                                  }
                                  src={media.image.mediaItemUrl}
                                  width={media.image.mediaDetails.width || 608}
                                />
                              )}
                              {media.linkText &&
                                media.url &&
                                media.companyName && (
                                  <div className={styles.linkText}>
                                    <Typography tag="span" xs={2}>
                                      {media.companyName}
                                    </Typography>
                                    <Button href={media.url}>
                                      {`(${media.linkText})`}
                                    </Button>
                                  </div>
                                )}
                            </Fragment>
                          ))}
                        </Column>
                        <Column
                          xs={12}
                          lg={
                            Number(content.split.customise.width) === 3
                              ? 9
                              : Number(content.split.customise.width) === 4
                                ? 8
                                : 6
                          }
                        >
                          {content.split.content.map((element, index) => {
                            // Find the index of the first occurrence of "title" in the array
                            const first = content.split && content.split.content.findIndex((elementItem) => elementItem.title);

                            // Determine whether the current item should use h2 or h3
                            const tag: 'h2' | 'h3' = index === first ? 'h2' : 'h3';

                            return (
                              <Fragment key={index}>
                                {element.chip && (
                                  <Chip className={styles.chip}>
                                    {element.chip}
                                  </Chip>
                                )}
                                {element.disclaimer && (
                                  <Typography
                                    className={styles.disclaimer}
                                    xs={1}
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          element.disclaimer,
                                        ),
                                      }}
                                    />
                                  </Typography>
                                )}
                                {element.editor && (
                                  <Typography className={styles.editor} xs={3}>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          element.editor,
                                        ),
                                      }}
                                    />
                                  </Typography>
                                )}
                                {element.feature && (
                                  <Feature
                                    className={styles.feature}
                                    customise={element.feature.customise}
                                    feature={element.feature.feature}
                                  />
                                )}
                                {element.highlight && (
                                  <Highlight
                                    className={styles.highlight}
                                    highlight={element.highlight}
                                  />
                                )}
                                {element.links && (
                                  <Buttons
                                    buttons={element.links}
                                    className={styles.links}
                                  />
                                )}
                                {element.logos && element.customise && (
                                  <Logos
                                    className={styles.logos}
                                    customise={element.customise}
                                    gap="small"
                                    logos={element.logos}
                                  />
                                )}
                                {element.hubspotForm && (
                                  <HubSpotForm
                                    form={element.hubspotForm}
                                    setIsFormLoaded={setIsFormLoaded}
                                  />
                                )}
                                {element.product && (
                                  <Product
                                    className={styles.product}
                                    product={element.product}
                                  />
                                )}
                                {element.quote && (
                                  <Quote
                                    className={styles.quote}
                                    direction="horizontal"
                                    quote={element.quote}
                                    size="small"
                                  />
                                )}
                                {element.spacer && (
                                  <Spacer spacer={element.spacer} />
                                )}
                                {element.table && (
                                  <Table
                                    className={styles.table}
                                    table={element.table}
                                  />
                                )}
                                {element.title && (
                                  <Typography
                                    className={styles.title}
                                    family="poppins"
                                    tag={tag}
                                    xs={5}
                                    md={6}
                                    lg={7}
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: DOMPurify.sanitize(
                                          element.title,
                                        ),
                                      }}
                                    />
                                  </Typography>
                                )}
                                {element.wpforms && (
                                  <Wpforms
                                    className={styles.wpforms}
                                    settings={settings}
                                    wpforms={element.wpforms}
                                  />
                                )}
                                {element.checks && (
                                  <Checks
                                    checks={element.checks}
                                    className={styles.checks}
                                  />
                                )}
                                {element.accordion && (
                                  <Accordion rows={element.accordion} />
                                )}
                                {element.kustomerForm && (
                                  <KustomerForm
                                    content={element.kustomerForm}
                                  />
                                )}
                              </Fragment>
                            );
                          })}
                        </Column>
                      </Row>
                    </div>
                  )}
                  {content.spotlights && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.spotlights.map((spotlight, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              md={columns === 12 ? undefined : 6}
                              lg={columns}
                            >
                              <Spotlight
                                customise={spotlight.customise}
                                spotlight={spotlight.spotlight}
                              />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.stats && content.customise && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Row justify={content.customise.alignment} xs={2}>
                        {content.stats.map((stats, index) => {
                          const columns: number = Number(
                            content.customise && content.customise.columns,
                          );
                          return (
                            <Column
                              key={index}
                              xs={12}
                              md={columns === 12 ? undefined : 6}
                              lg={columns}
                            >
                              <Stats stats={stats} style="bold" />
                            </Column>
                          );
                        })}
                      </Row>
                    </div>
                  )}
                  {content.table && (
                    <Table className={styles.table} table={content.table} />
                  )}
                  {content.testimonials && (
                    <div className={cn(styles.expand, styles.section)}>
                      <Testimonials
                        testimonials={content.testimonials}
                        design={content?.design}
                      />
                    </div>
                  )}
                  {content.title && (
                    <Typography
                      className={cn(styles.title, styles.expand)}
                      family="poppins"
                      tag={tag}
                      xs={tag === 'h1' ? 7 : 6}
                      md={tag === 'h1' ? 8 : 7}
                      lg={tag === 'h1' ? 9 : 8}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.title),
                        }}
                      />
                    </Typography>
                  )}
                  {content.video && (
                    <div className={styles.section}>
                      <Video video={content.video} />
                    </div>
                  )}
                  {content.wpforms && (
                    <div className={styles.section}>
                      <Wpforms settings={settings} wpforms={content.wpforms} />
                    </div>
                  )}
                  {content?.signUpForm && (
                    <div className={styles.section}>
                      <SignUpForm
                        form={{
                          formType: 'simple',
                          preloadTemplatesBy:
                            content?.signUpForm?.preloadTemplatesBy,
                          preloadTemplates:
                            content?.signUpForm?.preloadTemplates,
                          templateSource: content?.signUpForm?.templateSource,
                          showMembershipId:
                            content?.signUpForm?.showMembershipId &&
                            content.signUpForm.showMembershipId === 'yes'
                              ? true
                              : false,
                          excludeFromSafetycultureOnboardingComms:
                            content?.signUpForm
                              ?.excludeFromSafetycultureOnboardingComms &&
                            content.signUpForm
                              .excludeFromSafetycultureOnboardingComms === 'yes'
                              ? true
                              : false,
                          showAddressFields:
                            content?.signUpForm?.showAddressFields &&
                            content.signUpForm.showAddressFields === 'yes'
                              ? true
                              : false,
                          areYouAWoolGrower:
                            content?.signUpForm?.areYouAWoolGrower &&
                            content.signUpForm.areYouAWoolGrower === 'yes'
                              ? true
                              : false,
                          termsAndConditions:
                            content?.signUpForm?.termsAndConditions,
                          buttonText: content?.signUpForm?.buttonText,
                          title: content?.signUpForm?.title,
                          formId: content?.signUpForm?.formId,
                          redirect: content?.signUpForm?.redirect,
                          couponCode: content?.signUpForm?.couponCode,
                        }}
                      />
                    </div>
                  )}
                  {content?.lever && (
                    <div className={styles.expand}>
                      <Lever
                        url={content.lever.url}
                        allJobs={content.lever.allJobs}
                        maxJobs={content.lever.maxJobs}
                      />
                    </div>
                  )}
                  {content?.testimonialCta && (
                    <div className={styles.section}>
                      <TestimonialCta content={content?.testimonialCta} />
                    </div>
                  )}
                  {content.embed !== undefined && (
                    <Embeds content={content.embed} />
                  )}
                  {content?.eventbrite && content.eventbrite.id && (
                    <Eventbrite event={content.eventbrite} />
                  )}
                  {content?.roiCalculator && (
                    <div className={styles.section}>
                      <RoiCalculator content={content?.roiCalculator} />
                    </div>
                  )}
                  {content?.iconsList && (
                    <div className={styles.section}>
                      <IconsLIst iconsList={content.iconsList} />
                    </div>
                  )}
                  {content?.postType && (
                    <div className={styles.section}>
                      <RecentPosts
                        header={content?.header}
                        postType={content.postType}
                      />
                    </div>
                  )}
                </Fragment>
              ))}
            </Column>
          </Row>
        </Container>
      )}
    </section>
  );
};

export default Block;
