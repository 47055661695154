import { LeverPosting } from '@/utilities/types/Lever';

const apiLever = async (url: string): Promise<LeverPosting[]> => {
  const resp = await fetch(url);

  if (!resp.ok)
    throw new Error(`HTTP error when calling lever! status: ${resp.status}`);
  return await resp.json();
};

export default apiLever;
