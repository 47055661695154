import { IconProps } from '@/utilities/types/Icon';

const RotateRight = (props: IconProps) => {
  const { height = 16, width = 16 } = props;
  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 15 14"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99998 0.333344C3.31808 0.333344 0.333313 3.31811 0.333313 7.00001C0.333313 10.6819 3.31808 13.6667 6.99998 13.6667C10.0403 13.6667 12.6036 11.6321 13.406 8.85151C13.5081 8.49776 13.3041 8.12823 12.9503 8.02614C12.5965 7.92406 12.227 8.12809 12.1249 8.48184C11.4828 10.707 9.43047 12.3333 6.99998 12.3333C4.05446 12.3333 1.66665 9.94553 1.66665 7.00001C1.66665 4.05449 4.05446 1.66668 6.99998 1.66668C8.47359 1.66668 9.80655 2.26344 10.7725 3.23008C11.2777 3.73558 11.8513 4.42086 12.3128 5.00001H9.66665C9.29846 5.00001 8.99998 5.29849 8.99998 5.66668C8.99998 6.03487 9.29846 6.33334 9.66665 6.33334H13.6596C13.7312 6.33415 13.8031 6.32349 13.8726 6.30093C13.9526 6.27497 14.0259 6.2343 14.0895 6.18212C14.2386 6.06 14.322 5.88488 14.3323 5.70428C14.3331 5.68901 14.3335 5.67371 14.3333 5.6584V1.66668C14.3333 1.29849 14.0348 1.00001 13.6666 1.00001C13.2985 1.00001 13 1.29849 13 1.66668V3.72964C12.5987 3.24166 12.1421 2.71432 11.7157 2.2876C10.5098 1.08094 8.84158 0.333344 6.99998 0.333344Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RotateRight;
